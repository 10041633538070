import JSysenvTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JSysenvTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JSysenvTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JSysenvTabNote from '@/components/chart/tabs/JChartTabNote'

import JSysenvTabDatabase from './JSysenvTabDatabase'
import JSysenvTabStatus from './JSysenvTabStatus'
import JSysenvTabBorder from './JSysenvTabBorder'
import JSysenvTabForms from './JSysenvTabForms'

export default {
  JSysenvTabNavigation,
  JSysenvTabDatabase,
  JSysenvTabCanvas,
  JSysenvTabTitle,
  JSysenvTabStatus,
  JSysenvTabBorder,
  JSysenvTabForms,
  JSysenvTabNote,
}
