<template>
  <v-flex v-model="tabActivator" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Forms</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Background Templets
        <a class="close" v-on:click="collapsed['background'] = !collapsed['background']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['background']">
        <div class="type_common_select">
          <label class="type_gray">File</label>
          <input v-model="FormsTempletFile" type="text" placeholder="select file...." class="type147 algin_left algin_left">
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="FormsTempletX" type="text" class="type_double" />
          <input v-model="FormsTempletY" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        SVG Contents
        <a class="close" v-on:click="collapsed['svg'] = !collapsed['svg']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['svg']">
        <div class="type_common_select">
          <label class="type_gray">Folder</label>
          <input v-model="FormsSvgFolder" type="text" placeholder="select folder...." class="type147 algin_left">
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="FormsSvgX" type="text" class="type_double" />
          <input v-model="FormsSvgY" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        KeyPlan
        <a class="close" v-on:click="collapsed['key'] = !collapsed['key']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['key']">
        <div class="type_common_select">
          <label class="type_gray">File</label>
          <input v-model="FormsKeyplanFile" type="text" placeholder="select file...." class="type147 algin_left">
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="FormsKeyplanX" type="text" class="type_double" />
          <input v-model="FormsKeyplanY" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Legends
        <a class="close" v-on:click="collapsed['legends'] = !collapsed['legends']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['legends']">
        <div class="type_common_select">
          <label class="type_gray">File</label>
          <input v-model="FormsLegendFile" type="text" placeholder="select file...." class="type147 algin_left"> 
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="FormsLegendX" type="text" class="type_double" />
          <input v-model="FormsLegendY" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Bar Chart
        <a class="close" v-on:click="collapsed['bar'] = !collapsed['bar']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['bar']">
          <div class="type_common_select">
            <label class="type_gray">SQL</label>
            <v-btn @click.stop="exp.Q=true" class="type_openTextarea">Query</v-btn>
          </div>
          <j-modal-scripter 
            v-model="FormsChartSql" 
            sub-title="SQL Query"
            title="Access Database"
            :message="sqlMessage"
            :opened="exp.Q"
            :valid="sqlValidated"
            @close="exp.Q=false;"
            @save="exp.Q=false;onSave();"
            @validate="onValidate('FormsChartSql')"
          />
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-forms',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    tabActivator: null,
    collapsed: {
      background: true,
      svg: true,
      key: true,
      legends: true,
      bar: true,
    },
    exp: {
      Q: false,
    },
    validated: {
      DatabaseJSON: { valid: true, message: '' },
      QSummary: { valid: true, message: '' },
      QSp: { valid: true, message: '' },
    },

    formData: {
      background: { fileUrl: '', x: 0, y: 0 },
      svg: { folderUrl: '', x: 0, y: 0 },
      keyPlan: { fileUrl: '', x: 0, y: 0 },
      legends: { fileUrl: '', x: 0, y: 0 }
    }
  }),
  watch: {
    forms: {
      handler(val) {
        if (!val || Object.keys(val).length === 0) return
        this.formData = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['database', 'forms']),

    sqlValidated() { return this.validated.QSummary.valid },
    sqlMessage() { return this.validated.QSummary.message },

    FormsTempletFile: {
      get() { return this.formData.background.fileUrl },
      set(val) { 
        this.formData.background.fileUrl = val
        this.updateAttrs()
      }
    },
    FormsTempletX: {
      get() { return this.formData.background.x },
      set(val) { 
        this.formData.background.x = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsTempletY: {
      get() { return this.formData.background.y },
      set(val) { 
        this.formData.background.y = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsSvgFolder: {
      get() { return this.formData.svg.folderUrl },
      set(val) { 
        this.formData.svg.folderUrl = val
        this.updateAttrs()
      }
    },
    FormsSvgX: {
      get() { return this.formData.svg.x },
      set(val)  { 
        this.formData.svg.x = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsSvgY: {
      get() { return this.formData.svg.y },
      set(val)  { 
        this.formData.svg.y = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsKeyplanFile: {
      get() { return this.formData.keyPlan.fileUrl },
      set(val) { 
        this.formData.keyPlan.fileUrl = val
        this.updateAttrs()
      }
    },
    FormsKeyplanX: {
      get() { return this.formData.keyPlan.x },
      set(val) { 
        this.formData.keyPlan.x = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsKeyplanY: {
      get() { return this.formData.keyPlan.y },
      set(val) { 
        this.formData.keyPlan.y = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsLegendFile: {
      get() { return this.formData.legends.fileUrl },
      set(val) { 
        this.formData.legends.fileUrl = val
        this.updateAttrs()
      }
    },
    FormsLegendX: {
      get() { return this.formData.legends.x },
      set(val) { 
        this.formData.legends.x = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsLegendY: {
      get() { return this.formData.legends.y },
      set(val) { 
        this.formData.legends.y = parseFloat(val)
        this.updateAttrs()
      }
    },
    FormsChartSql: {
      get() { return this.database.qSummary },
      set(val) { this.setSvgProps({ qSummary: val } )}
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['setSvgProps']),

    updateAttrs() {
      this.setSvgProps({ forms: JSON.parse(JSON.stringify(this.formData)) })
    }
  }
}
</script>
