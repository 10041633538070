<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Border</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
          v-model="msgOpen"
          :title="msgInfo.title"
          :type="msgInfo.type"
          :message="msgInfo.message"
          :button="msgInfo.button"
          :button-text="msgInfo.buttonText"
          @yes="yes()"
          @cancel="msgOpen = false"
        />
      <div class="header">
        Border type
        <a class="close" v-on:click="collapsed['border'] = !collapsed['border']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['border']">
        <div v-if="localBorderAttrs.length === 0">
          <span data-grid-quickbtn="btn" class="label" @click="appendAttr()">
            <span class="jcon_temp_plus float_left"> </span> Default
          </span>
        </div>
        <div v-for="(attr, i) in localBorderAttrs" :key="`_border_${i}`">
          <span v-if="i === 0" data-grid-quickbtn="btn" class="label" @click="appendAttr()"><span class="jcon_temp_plus float_left "> </span> Default</span>
          <span v-else data-grid-quickbtn="btn" class="label jcon_temp_minus float_left" @click="clickRemoveBtn(i)">Shape
          </span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Shape</label>
              <span class="shape_flex">
                <span class="border border_circle" :class="{ selected: 'circle' == attr.shape }" @click="updateAttr('circle', i, 'shape')"></span>
                <span class="border border_rect" :class="{ selected: 'rect' == attr.shape }" @click="updateAttr('rect', i, 'shape')"></span>
                <span class="border border_ellipse" :class="{ selected: 'ellipse' == attr.shape }" @click="updateAttr('ellipse', i, 'shape')"></span>
              </span>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Code</label>
              <input type="text" :value="attr.type" @input="updateAttr" />
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Width / Height</label>
              <div class="input_flex_wrap_end">
                <input type="text" class="type_double" :value="attr.width" @input="updateAttr" />
                <input type="text" :value="attr.height" @input="updateAttr" />
              </div>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font Size / Style</label>
              <div class="input_flex_wrap_end">
                <input type="text" class="type22" :value="attr.textSize" @input="updateAttr" />
                <select dir="rtl" class="type70" style="margin-left: 0.2rem" :value="attr.textStyle" @input="(e) => { updateAttr(e.target.value, i, 'textStyle') }">
                  <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Stroke / Color</label>
              <div class="input_flex_wrap_end">
                <input type="text" class="type22" :value="attr.stroke" @input="updateAttr" />
                <j-color-picker v-model="attr.color" @input="updateAttr"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Padding</label>
              <div class="input_flex_wrap_end">
                <input type="text" class="type22" :value="attr.pading" @input="updateAttr" />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-border',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      border: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localBorderAttrs: [],

    msgOpen: false,
    yes: null
  }),
  watch: {
    border: {
      handler(val) {
        if (!val || val.length === 0) return
        this.localBorderAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['border']),

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['setSvgProps']),

    appendAttr() {
      this.localBorderAttrs.push({ 
        type: '',
        shape: '',
        width: 0,
        height: 0,
        color: '',
        stroke: 0,
        textSize: 0,
        pading: 0,
      })
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index) {
      this.localBorderAttrs[index] = ''
      this.localBorderAttrs = this.localBorderAttrs.filter(v => !!v)
      this.updateAttr()
    },
    updateAttr(v, i, name) {
      if(name) this.localBorderAttrs[i][name] = v
      this.setSvgProps({ border: this.localBorderAttrs })
    },
  }
}
</script>
