<template>
  <v-flex v-model="activator_" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Status</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        Status Codes
        <a class="close" v-on:click="collapsed['status'] = !collapsed['status']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['status']">
        <div class="input_flex_wrap code_title_wrapper">
          <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr()">
            <label style="color:#757575">Default Code</label>
          </div>
        </div>
        <div
          class="type_common_input type_color_float"
          v-for="(attr, i) in localStyleAttrs"
          :key="`_style_local__${i}`"
        >
          <div class="input_flex_wrap margin_0">
            <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn(i)"></div>
            <input v-model="attr.type" class="type_gray w110" type="text" @focusout="updateAttr" />
            <j-color-picker v-model="attr.bgColor" @input="updateAttr" title />
            <j-color-picker v-model="attr.textColor" @input="updateAttr" title>
              <template #action="{ action }">
                <div
                  v-ripple
                  v-on="action.on"
                  class="j_button_color_picker w36"
                  :style="`color: ${attr.textColor}; background-color: ${attr.bgColor};`"
                  @click="action.open"
                >CODE</div>
              </template>
            </j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'


export default {
  name: 'j-sysenv-tab-status',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      status: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localStyleAttrs: [],
    activator_: null,

    msgOpen: false,
    yes: null
  }),
  watch: {
    style: {
      handler(val) {
        if (!val || val.length === 0) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['style']),
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['setSvgProps']),

    appendAttr(typeName) {
      this.localStyleAttrs.push({ type: '', bgColor: '', textColor: '' })
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index) {
      this.localStyleAttrs[index] = null
      this.localStyleAttrs = this.localStyleAttrs.filter(v => !!v)
      this.updateAttr()
    },
    updateAttr() {
      this.setDatagridItem({ styleAttrs: this.localStyleAttrs })
    }
  }
}
</script>
