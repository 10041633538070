<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Database</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Data Source
        <a class="close" v-on:click="collapsed['source'] = !collapsed['source']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['source']">
        <div class="type_common_input">
          <span class="label">Use Direct Access</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="directAccess" type="radio" name="directAccess" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-show="isDirectAccess" class="type_inner">
          <div class="type_common_input type_gray">
            <label>Database</label>
            <select v-model="dbName" @input="(e) => { databaseChanged(e.target.value) }">
              <option 
                v-for="option in databaseOptions" :key="option.index" 
                :value="option.dbName"
              >{{option.dbName}}</option>
            </select>
          </div>
          <div class="type_common_input type_gray">
            <label>Table/View</label>
            <select v-model="tableName" @input="(e) => { tableChanged(e.target.value) }">
              <option 
                v-for="option in tableOptions" :key="option.index" 
                :value="option.tableName"
              >{{option.tableName}}</option>
            </select>
          </div>
        </div>
        <v-spacer v-show="isDirectAccess" style="height: 10px;"></v-spacer>
        <div class="type_common_input">
          <span class="label">Run SQL {{ isDirectAccess ? 'With' : 'From' }}</span>
          <div v-show="!isDirectAccess" class="row">
            <label v-for="item in databaseAccessOptionsNJ" :key="item.id" class="type_many">
              <input v-model="qApplied" name="DatabaseAccess" type="radio" :value="item.val" />
              {{item.text}}
            </label>
          </div>
        </div>
        <div class="column align_end">
          <div class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">SQL Query</label>
            <div justify="center">
              <v-btn class="type_openTextarea" @click.stop="exp.Q = true">{{ isDirectAccess ? 'Extended Query' : 'Main Query' }}</v-btn>
              <j-modal-scripter 
                v-model="qDataset" 
                sub-title="SQL Query"
                title="Access Database"
                :message="sqlDatasetMessage"
                :opened="exp.Q"
                :valid="sqlDatasetValidated"
                @close="exp.Q=false;"
                @save="exp.Q=false;onSave();"
                @validate="onValidate('qDataset')"
              />
            </div>
          </div>
          <div v-if="!isDirectAccess" class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">Stored Procedure</label>
            <div justify="center">
              <v-btn class="type_openTextarea" @click.stop="exp.S=true">T-SQL Procedure Script</v-btn>
              <j-modal-scripter 
                v-model="qSp" 
                sub-title="Stored Procedure"
                title="Access Database"
                :message="spMessage"
                :opened="exp.S"
                :valid="spValidated"
                @close="exp.S=false;"
                @save="exp.S=false;onSave();"
                @validate="onValidate('qSp')"
              />
            </div>
          </div>
        </div>
        <div>
          <v-spacer style="height: 10px;"></v-spacer>
          <div class="type_common_input">
            <span class="label">Summary Query</span>
          </div>
          <div class="column align_end">
            <div class="column align_end">
              <div justify="center">
                <v-btn class="type_openTextarea" @click.stop="exp.T = true" />
                <j-modal-scripter 
                  v-model="qSummary" 
                  sub-title="Summary Query"
                  title="Access Database"
                  :message="sqlSummaryMessage"
                  :opened="exp.T"
                  :valid="sqlSummaryValidated"
                  @close="exp.T=false;"
                  @save="onSave();exp.T=false;"
                  @validate="onValidate('qSummary')"
                />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Import Reference Data
        <a
          class="close"
          v-on:click="collapsed['import'] = !collapsed['import']"
        ></a>
      </div>
      <v-flex class="control" v-show="collapsed['import']">
        <div class="column align_end">
          <select v-model="chartReference" class="type100">
            <option 
              v-for="item in chartReferenceOptions" :key="item.index" 
              :value="item.val"
            >{{item.text}}</option>
          </select>
          <a class="type01">Import Data</a>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Configuration
        <a class="close" v-on:click="collapsed['config'] = !collapsed['config']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['config']">
        <div class="type_common_input">
          <label>Version</label>
          <input v-model="version" readonly type="text"  class="type70" />
        </div>
        <div class="column align_end">
          <label class="alignself_start">JSON Properties</label>
          <div justify="center">
            <v-btn @click.stop="exp.J=true" class="type_openTextarea">Filtering Options</v-btn>
            <j-modal-scripter 
              v-model="jsonProps" 
              sub-title=""
              title="JSON Properties"
              :message="jsonMessage"
              :opened="exp.J"
              :valid="jsonValidated"
              @close="exp.J=false;"
              @save="exp.J=false;onSave();"
              @validate="onValidate('jsonProps')"
            />
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import JsonLint from 'jsonlint'
import JsonValidator from 'json-validator'

import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService, SystemValuesService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-database',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  props: {
    type: String
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemValuesService: null,

    // rules -------------------------
    codeRules: [v => (!!v && v != 'ALL') || "Required"],
    defaultRules: [v => !!v || "Required"],

    // general -----------------------
    collapsed: {
      source: true,
      import: true,
      config: true
    },
    exp: {
      J: false,
      Q: false,
      S: false,
      T: false,
    },
    validated: {
      jsonProps: { valid: true, message: '' },
      qSummary: { valid: true, message: '' },
      qDataset: { valid: true, message: '' },
      qSp: { valid: true, message: '' },
    },

    chartReference: '',
    chartReferenceOptions: [],
    databaseOptions: [],
    tableOptions: [],
    jsonPropsPrev: [],

    bar3d_general: null,
  }),
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['database']),

    jsonValidated() { return this.validated.jsonProps.valid },
    jsonMessage() { return this.validated.jsonProps.message },
    sqlSummaryValidated() { return this.validated.qSummary.valid },
    sqlSummaryMessage() { return this.validated.qSummary.message },
    sqlDatasetValidated() { return this.validated.qDataset.valid },
    sqlDatasetMessage() { return this.validated.qDataset.message },
    spValidated() { return this.validated.qSp.valid },
    spMessage() { return this.validated.qSp.message },

    isDirectAccess() { return this.directAccess == 'Y' ? true : false },

    directAccess: {
      get() { return this.database.directAccess },
      set(val) { this.setSvgProps({ directAccess: val })}
    },
    dbName: {
      get() { return this.database.dbName },
      set(val) { this.setSvgProps({ dbName: val })}
    },
    schemaName: {
      get() { return this.database.schemaName },
      set(val) { this.setSvgProps({ schemaName: val })}
    },
    tableName: {
      get() { return this.database.tableName },
      set(val) { this.setSvgProps({ tableName: val })}
    },
    qApplied: {
      get() { return this.database.qApplied },
      set(val) { this.setSvgProps({ qApplied: val })}
    },
    qSummary: {
      get() { return this.database.qSummary },
      set(val) { this.setSvgProps({ qSummary: val })}
    },
    qDataset: {
      get() { return this.database.qDataset },
      set(val) { this.setSvgProps({ qDataset: val })}
    },
    qSp: {
      get() { return this.database.qSp },
      set(val) { this.setSvgProps({ qSp: val })}
    },
    version: {
      get() { return this.database.version },
      set(val) { this.setSvgProps({ version: val })}
    },
    jsonProps: {
      get() { return this.database.jsonProps },
      set(val) { this.setSvgProps({ jsonProps: val })}
    },
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemValuesService = new SystemValuesService()
  },
  mounted() {
    this.systemValuesService.sysValuesDatabases(res => {
      if (!res) this.databaseOptions = []
      else this.databaseOptions = res
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.SVGCONTROL_ADMIN, ['setSvgProps']),

    databaseChanged(db) {
      this.systemValuesService.sysValuesTables(db, res => {
        if (!res) this.tableOptions = []
        else this.tableOptions = res
      })
    },
    tableChanged(table) {
      let tableOption_ = this.tableOptions.find(option => option.tableName == table)
      if(!tableOption_) return

      this.schemaName = tableOption_.schemaName
    },
    onJsonFormat(e) {
      this.jsonValidated = ''

      try {
        JsonLint.parse(this.jsonProps)
      } catch(err) {
        this.jsonValidated = err
        return
      }

      // TO DO : 
      // - Validate the elements available & its value-option valid
      // - Check the value field empty
      // - Check at least one 'sortable' available
      // - Check type 'code' placed in the group level
      // - Check visible false for type 'code'
      
      this.jsonProps = JSON.stringify(JSON.parse(this.jsonProps), null, 4)
    },
    onJsonOpen() {
      this.jsonValidated = ''
      this.jsonOpened = true
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(typeName) {
      if(typeName == 'jsonProps') {
        this.validated.jsonProps = this.validateJson(this.jsonProps)
        if(this.validated.jsonProps.valid) this.jsonProps = this.validated.jsonProps.json
      } else {
        this.validated[typeName] = this.validateSql(this[typeName])
      }
    },
    setData(mode) {
      if(mode == __C.FORM.EDIT_MODE_NEW) {
        // 
      } else {
        // if this.dbName is null/empty/undefinded, cors error will be occurred.
        if(this.dbName) this.databaseChanged(this.dbName)
      }
    }
  }
}
</script>
